import React from 'react';
import classNames from 'classnames';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  return (
    <svg
      className={classNames('monogram', className)}
      width="50"
      height="50"
      viewBox="0 0 256 256"
      {...props}
    >
      <g id="Group_7" data-name="Group 7" transform="translate(-2057 1016)">
        <rect
          id="Rectangle_8"
          data-name="Rectangle 8"
          style={{ fill: 'rgba(255,255,255,0)' }}
          width="100%"
          height="100%"
          transform="translate(2057 -1016)"
          fill="#ffffff"
        />
        <g id="Group_1" data-name="Group 1" transform="translate(1105.777 -1246)">
          <path
            id="Path_24"
            data-name="Path 24"
            d="M0,302.667,32.667,270V373.444L0,340.778Z"
            transform="translate(1003 82.556)"
            fill={'var(--rgbAccent)'}
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M32.667,292.667,0,260V363.444l32.667-32.667Z"
            transform="translate(1122.778)"
            fill={'var(--rgbAccent)'}
          />
          <path
            id="Path_23"
            data-name="Path 23"
            d="M-954.964,201h0L-1107.409,48.556V5l152.444,152.444V201h0v0Z"
            transform="translate(2110.409 255)"
            fill={'var(--rgbAccent)'}
          />
        </g>
      </g>
    </svg>
  );
}

export default Monogram;
