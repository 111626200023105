export const navLinks = [
  {
    label: 'About',
    pathname: '/',
    hash: '#about',
  },
  {
    label: 'Projects',
    pathname: '/',
    hash: '#projects',
  },
  {
    label: 'Contact',
    pathname: '/',
    hash: '#contact',
  },

];

export const socialLinks = [
  {
    label: 'linkedin',
    url: 'https://www.linkedin.com/in/nimish-jain-837474194/',
    icon: 'linkedin',
  },
  {
    label: 'Github',
    url: 'https://github.com/NIMISH-25',
    icon: 'github',
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/_nimish.jain/',
    icon: 'instagram',
  },
];
