import { useRef, useState, memo } from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import Monogram from 'components/Monogram';
import Icon from 'components/Icon';
import NavToggle from './NavToggle';
import ThemeToggle from './ThemeToggle';
import { useWindowSize, useAppContext } from 'hooks';
import { navLinks, socialLinks } from './navData';
import { reflow } from 'utils/transition';
import { media, msToNum, numToMs } from 'utils/style';
import { tokens } from 'components/ThemeProvider/theme';
import { blurOnMouseUp } from 'utils/focus';
import './index.css';

const NavbarIcons = () => (
  <div className="navbar_nav-icons">
    {socialLinks.map(({ label, url, icon }) => (
      <a
        key={label}
        className="navbar_nav-icon-link"
        aria-label={label}
        href={url}
        onMouseUp={blurOnMouseUp}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon className="navbar_nav-icon" icon={icon} />
      </a>
    ))}
  </div>
);

function Navbar(props) {
  const { menuOpen, dispatch } = useAppContext();
  const [hashKey, setHashKey] = useState();
  const windowSize = useWindowSize();
  const navbarRef = useRef();
  const isMobile = windowSize.width <= media.mobile || windowSize.height <= 696;

  const handleNavClick = () => {
    setHashKey(Math.random().toString(32).substr(2, 8));
  };

  const handleMobileNavClick = () => {
    handleNavClick();
    if (menuOpen) dispatch({ type: 'toggleMenu' });
  };

  return (
    <div>
      <div className="the-blur"></div>
      <header className="navbar" ref={navbarRef}>
        <RouterLink
          className="navbar_logo"
          to={{ pathname: '/', hash: '#intro', state: hashKey }}
          aria-label="Nimish Jain, Fullstack Developer"
          onClick={handleMobileNavClick}
          onMouseUp={blurOnMouseUp}
        >
          <Monogram highlight />
        </RouterLink>
        <NavToggle onClick={() => dispatch({ type: 'toggleMenu' })} menuOpen={menuOpen} />

        <nav className="navbar_nav">
          <div className="navbar_nav-list">
            {navLinks.map(({ label, pathname, hash }) => (
              <NavLink
                exact="true"
                className="navbar_nav-link"
                onClick={handleNavClick}
                key={label}
                to={{ pathname, hash, state: hashKey }}
                onMouseUp={blurOnMouseUp}
              >
                {label}
              </NavLink>
            ))}
          </div>
          <NavbarIcons />
        </nav>
        <Transition
          mountOnEnter
          unmountOnExit
          in={menuOpen}
          timeout={{ enter: 0, exit: msToNum(tokens.base.durationL) }}
          onEnter={reflow}
        >
          {status => (
            <nav className={`navbar_mobile-nav navbar_mobile-nav--${status}`}>
              {navLinks.map(({ label, pathname, hash }, index) => (
                <NavLink
                  className={`navbar_mobile-nav-link navbar_mobile-nav-link--${status}`}
                  key={label}
                  onClick={handleMobileNavClick}
                  to={{ pathname, hash, state: hashKey }}
                  onMouseUp={blurOnMouseUp}
                  style={{
                    transitionDelay: numToMs(
                      Number(msToNum(tokens.base.durationS)) + index * 50
                    ),
                  }}
                >
                  {label}
                </NavLink>
              ))}
              <NavbarIcons />
              <ThemeToggle isMobile />
            </nav>
          )}
        </Transition>
        {!isMobile && <ThemeToggle />}
      </header>
    </div>
  );
}

export default memo(Navbar);
