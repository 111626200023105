import { forwardRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import Icon from "components/Icon";
import { blurOnMouseUp } from "utils/focus";
import "./index.css";

export const Button = forwardRef(
  (
    {
      className,
      as,
      secondary,
      loading,
      loadingText = "loading",
      icon,
      iconEnd,
      iconHoverShift,
      iconOnly,
      children,
      rel,
      target,
      href,
      download,
      ...rest
    },
    ref
  ) => {
    const isExternalLink = href?.includes(".com");
    const useLinkTag = isExternalLink || href?.[0] === "#";
    const linkComponent = useLinkTag ? "a" : Link;
    const defaultComponent = href ? linkComponent : "button";
    const Component = as || defaultComponent;

    const additionalAttributes = download
      ? {
          download: true,
          rel: "noopener noreferrer",
          target: "_blank",
        }
      : {};

    return (
      <Component
        className={classNames("button", className, {
          "button--loading": loading,
          "button--icon-only": iconOnly,
          "button--secondary": secondary,
        })}
        href={href && isExternalLink ? href : undefined}
        to={href && !isExternalLink ? href : undefined}
        onMouseUp={blurOnMouseUp}
        ref={ref}
        {...rest}
        {...additionalAttributes}
      >
        {!!icon && (
          <Icon
            className={classNames("button_icon", {
              "button_icon--start": !iconOnly,
              "button_icon--shift": iconHoverShift,
            })}
            icon={icon}
          />
        )}
        {!!children && <span className="button_text">{children}</span>}
        {!!iconEnd && (
          <Icon
            className={classNames("button_icon", {
              "button_icon--end": !iconOnly,
              "button_icon--shift": iconHoverShift,
            })}
            icon={iconEnd}
          />
        )}
        {loading && (
          <Loader className="button_loader" size={32} text={loadingText} />
        )}
      </Component>
    );
  }
);
